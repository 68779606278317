import React from "react";
import { Box, Heading, Text, Flex, Center } from "@chakra-ui/react";
import {Card} from "./Card";

interface WorkLayoutWithCardsProps {
  title: string;
  cards: Array<{
    id: string;
    title: string;
    description: string;
    imageSrc: string;
    link: string;
  }>;
  footerNote: string;
}

export const WorkLayoutWithCards: React.FC<WorkLayoutWithCardsProps> = ({
  title,
  cards,
  footerNote,
}) => {
  return (
    <Box minH={["auto", "60vh", "70vh"]} mx={[5,5,20]} position="relative" pb={[24, 16]}>
      {cards.length > 0 ? (
        <Flex flexDirection="column" minH="100%">
          <Flex flexWrap="wrap" justifyContent="center" mb={8} flex="1">
            {cards.map((card) => (
              <Box key={card.id} width={["100%", "calc(50% - 12px)", "calc(33.33% - 16px)"]} mb={[4, 8]}>
                <Card
                  imageSrc={card.imageSrc}
                  heading={card.title}
                  description={card.description}
                  onClick={() => window.open(card.link)}
                />
              </Box>
            ))}
          </Flex>
          <Text 
            fontSize="sm" 
            fontStyle="italic" 
            textAlign="center" 
            color="#FFFFFF6E" 
            mt={[8, 16]}
          >
            {footerNote}
          </Text>
        </Flex>
      ) : (
        <Center h="100%">
          <Heading as="h2" size="xl">
            Coming Soon
          </Heading>
        </Center>
      )}
    </Box>
  );
};
