import React from "react";
import { Card as ChakraCard, CardBody, Heading, Image, Stack, Text } from "@chakra-ui/react";

export const Card = ({ imageSrc, heading, description, onClick }: any) => {
  const text = new DOMParser()
    .parseFromString(description, "text/html")
    .querySelector("p")?.textContent;

  return (
    <ChakraCard
      shadow={0}
      zIndex={1}
      maxW={80}
      maxH={"sm"}
      my={"1rem"}
      mx={"1rem"}
      display={"flex"}
      transition={"transform 1s"}
      cursor={"pointer"}
      _hover={{
        shadow: "2xl",
        transform: "scale(1.05)",
        backdropFilter: `blur( 2px )`,
      }}
      bg={"blackAlpha.100"}
      onClick={onClick}
    >
      <CardBody>
        <Image
          src={imageSrc}
          borderRadius={"lg"}
          alt={"thumbnail image for card"}
          h={["auto", "auto", 48]}
          objectFit={"cover"}
        />
        <Stack mt={6} spacing={3}>
          <Heading size={"md"} fontWeight={"300"} color={"#CEA7F3"}>
            {heading}
          </Heading>
          <Text color={"#FFFFFF6E"} noOfLines={3} fontSize={"sm"}>
            {text ?? description}
          </Text>
        </Stack>
      </CardBody>
    </ChakraCard>
  );
};