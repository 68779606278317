import { useState, useEffect } from "react";
import axios from "axios";

export interface Post {
  id: string;
  title: string;
  link: string;
  author: string;
  published: number;
  created: number;
  category: string[];
  content: string;
  description: string; // Add this line
  enclosures: any[];
  content_encoded: string;
  media: any;
  thumbnail: string;
}

export const usePosts = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@knackofabhinav"
      );
      
      const postsWithThumbnails = res.data.items.map((post: Post) => ({
        ...post,
        thumbnail: post.description.match(/<img[^>]+src="([^">]+)"/)?.[1] || ''
      }));

      setPosts(postsWithThumbnails);
    };

    fetchPosts();
  }, []);

  return posts;
};