import React, { useEffect, useState } from "react";
import type { HeadFC } from "gatsby";

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, SimpleGrid, Text, Image, Link, Flex } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { WorkLayoutWithCards } from "../components/WorkLayoutWithCards";
import { usePosts } from "../hooks/usePosts";
import { useVideos } from "../hooks/useVideos";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { TabProps } from "@chakra-ui/react";
import KojoyoImage from "../images/work/kojoyo.jpeg";

interface Project {
  id: string;
  title: string;
  description: string;
  imageSrc: string;
  link: string;
}

const projects: Project[] = [
  {
    id: "1",
    title: "Kojoyo - Personal Finance App",
    description: "A comprehensive personal finance app that helps you manage your finances, track your expenses, and save money.",
    imageSrc: KojoyoImage,
    link: "https://knackofabhinav.notion.site/Kojoyo-Personal-Finance-App-b793e445b9f4434ca589d6450f55bc58?pvs=4"
  },
  // You can add more projects here in the future
];

const Work = () => {
  const [isLoading, setIsLoading] = useState(true);
  const posts = usePosts();
  const videos = useVideos();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (posts.length > 0 && videos.length > 0) {
      setIsLoading(false);
      document.body.style.overflow = 'auto';
    }
  }, [posts, videos]);

  if (isLoading) {
    return <Layout><Box minHeight="100vh">Loading...</Box></Layout>;
  }

  return (
    <Layout withFooter >
      <Box minHeight="100vh">
        <Tabs
          pt={20}
          size="md"
          variant="unstyled"
          isManual
          isFitted
          isLazy
          defaultIndex={1}
          zIndex={2}
        >
          <TabList
            justifyContent={"space-around"}
            position={"sticky"}
            top={20}
            zIndex={3}
            bg={
              "linear-gradient(90.51deg, rgba(0, 0, 0, 0.37) 0.50%, rgba(114, 114, 114, 0.04) 100%)"
            }
            backdropFilter={`blur( 2px )`}
          >
            <CustomTab>Blogs</CustomTab>
            <CustomTab>Videos</CustomTab>
            <CustomTab>Projects</CustomTab>
          </TabList>

          <TabPanels>
            <TabPanel minHeight="calc(100vh - 100px)">
              <WorkLayoutWithCards
                title={"Latest Blogs"}
                cards={posts.map((post) => ({
                  ...post,
                  imageSrc: post.thumbnail,
                }))}
                footerNote={`The beauty of blogging is the ability to share your thoughts, ideas, and experiences with the world. It's a platform for personal growth and self-expression, and a way to connect with like-minded individuals and learn from each other.`}
              />
            </TabPanel>
            <TabPanel minHeight="calc(100vh - 100px)">
              <WorkLayoutWithCards
                title={"Latest Videos"}
                cards={videos
                  .map((video) => ({
                    id: video.id.videoId,
                    title: video.snippet.title,
                    imageSrc: video.snippet.thumbnails.high.url,
                    description: video.snippet.description,
                    link: `http://www.youtube.com/watch?v=${video.id.videoId}`,
                  }))
                    .filter((video) => video.id)}
                footerNote={`Video is a powerful way to share your message and connect with others. Create videos to be creative, expressive, and engaging.`}
              />
            </TabPanel>
            <TabPanel minHeight="calc(100vh - 100px)">
              <WorkLayoutWithCards
                title={"Latest Projects"}
                cards={projects}
                footerNote={`For me, every day is a new thing. I approach each project with a new insecurity, almost like the first project I ever did. And I get the sweats. I go in and start working, I'm not sure where I'm going. If I knew where I was going I wouldn't do it.`}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

const CustomTab: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Tab
    _selected={{ color: "#E4B18E", bg: "#4B244A" }}
    fontSize={["lg", "xl", "2xl"]}
    color="white"
    fontWeight={500}
  >
    {children}
  </Tab>
);

export default Work;

export const Head: HeadFC = () => <title>Work - Abhinav Patel</title>;
