import { useState, useEffect } from "react";
import axios from "axios";

interface Video {
  id: {
    videoId: string;
  };
  snippet: {
    title: string;
    description: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
  };
}

export const useVideos = () => {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await axios.get(
        "https://www.googleapis.com/youtube/v3/search?key=AIzaSyAUH3k0NYQfmYQY6KXi3qwFpO04BF4iWBo&channelId=UCGDa9prSFRQstz-AVAwKWoQ&part=snippet,id&order=date&maxResults=20"
      );
      setVideos(response.data.items);
    };

    fetchVideos();
  }, []);

  return videos;
};